import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

type ProgramMutedState = {
	muted: boolean
}

export const programMutedState = atom<ProgramMutedState>({
	key: 'programMutedState',
	default: {
		muted: true,
	},
})

export const useProgramMuted = () => {
	const [state, setState] = useRecoilState(programMutedState)

	const setProgramMuted = useCallback(
		(value: boolean) => {
			setState({ muted: value })
		},
		[setState],
	)

	return { programMuted: state.muted, setProgramMuted }
}

import { useLayoutEffect } from 'react'
import useIframeZoom from '@hooks/useIframeZoom'
import { Box } from '@mui/joy'
import { theme } from '@ui/style'
import pxToRem from '@utils/pxToRem'
import setAds from '@utils/setAds'

const ArticleMoAd = () => {
	const { iframeStyles } = useIframeZoom()

	useLayoutEffect(() => {
		setAds({
			id: '-Sp5urbBSc-G86JGD3rjJw',
			inventory: 'jtbc_m/article_news/article_news@article_news_top_320x50?mlink=576',
		})
	}, [])

	return (
		<Box
			display={{ xs: 'flex', md: 'none' }}
			height={pxToRem(50)}
			bgcolor="#f8f8f8"
			justifyContent="center"
			alignItems="center"
			sx={{
				'&>div': {
					height: pxToRem(50),
					[theme.mediaQueries.under.origin]: {
						width: pxToRem(320),
						overflow: 'hidden',
					},
				},
				'&>div>iframe': iframeStyles,
			}}
			id="-Sp5urbBSc-G86JGD3rjJw_AD"
		/>
	)
}
export default ArticleMoAd

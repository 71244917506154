'use client'

import { useEffect } from 'react'
import TabBarRoot from './TabBarRoot'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { IsAppState } from '@store/isApp'
import { useIsFocusedControl } from '@store/isFocused'

interface TabBarProps {
	isApp: boolean
}
const TabBar = ({ isApp }: TabBarProps) => {
	const [appStateLocal] = useLocalStorage<string>('app_state')
	const appState = appStateLocal ? (JSON.parse(appStateLocal) as IsAppState) : undefined
	const { setIsFocused } = useIsFocusedControl()
	const handleInputTarget = (target: HTMLElement) => {
		if (target instanceof HTMLInputElement && !['checkbox', 'radio'].includes(target.type)) {
			return true
		}
		if (target instanceof HTMLTextAreaElement && target.type === 'textarea') {
			return true
		}
		if (target.contentEditable === 'true') {
			return true
		}
		return false
	}
	const handleFocus = (e: FocusEvent) => {
		if (handleInputTarget(e.target as HTMLElement)) {
			setIsFocused(true)
		}
	}
	const handleBlur = (e: FocusEvent) => {
		if (handleInputTarget(e.target as HTMLElement)) {
			setIsFocused(false)
		}
	}
	useEffect(() => {
		document.body.addEventListener('focus', handleFocus, true)
		document.body.addEventListener('blur', handleBlur, true)
		return () => {
			document.body.removeEventListener('focus', handleFocus, true)
			document.body.removeEventListener('blur', handleBlur, true)
		}
	}, [])
	return (isApp || appState?.isApp) && <TabBarRoot />
}

export default TabBar

import { forwardRef, useEffect } from 'react'
import UISimpleModalRoot, { UISimpleModalRootProps } from './UISimpleModalRoot'
import useSetRefreshValue from '@hooks/Interface/useSetRefreshValue'

const UISimpleModal = forwardRef<HTMLDivElement, UISimpleModalRootProps>((props, ref) => {
	const { setRefreshValue } = useSetRefreshValue()
	useEffect(() => {
		if (props.open) {
			setRefreshValue(false)
		}
		return () => {
			setRefreshValue(true)
		}
	}, [props.open])
	return <UISimpleModalRoot ref={ref} {...props} />
})

export default UISimpleModal

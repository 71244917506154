'use client'

import UIContainer from '@components/ui/UIContainer'
import { useMedia } from '@hooks/useMedia'
import { useMenuPc } from '@services/api/Menu/Pc'
import { useMenuTop } from '@services/api/Menu/Top'
import { useTopKeyword } from '@services/api/NewsHome/TopKeyword'
import { useSNSList } from '@services/api/SNS/List'
import { useCallback, useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import HeaderGroup from './HeaderGroup'
import IssueBox from './IssueBox'
import NavBox from './NavBox'
import Setting from './Setting'
import {
	HeaderStateManager,
	HeaderStates,
	headerRefAtom,
	headerStatesAtom,
	useHeaderStates,
} from './store/store'
import { HeaderBox } from './styled'
import BreakingNews from './BreakingNews'
import { usePathname } from 'next/navigation'
import { useIsLoadPageControl } from '@store/isLoadPage'
import HeaderDimmed from './HeaderDimmed'
import ServiceIntroHeader from './ServiceIntroHeader'
import useSetRefreshValue from '@hooks/Interface/useSetRefreshValue'

export type HeaderProps = {
	isApp?: boolean
	auth?: boolean
}
const Header = ({ isApp = false, auth = false }: HeaderProps) => {
	const pathname = usePathname()
	const isService = pathname.includes('/service')
	const deviceMedia = useMedia()
	const { setIsLoadPage } = useIsLoadPageControl()
	const { setRefreshValue } = useSetRefreshValue()
	const headerState = useRecoilValue(headerStatesAtom)

	const { data: menuTop, refetch: refetchMenuTop, isLoading: isLoadingMemuTop } = useMenuTop()
	const { data: menuPc, refetch: refetchMenuPc, isLoading: isLoadingMemuPc } = useMenuPc()
	const {
		data: topKeyword,
		refetch: refetchTopKeyword,
		isLoading: isLoadingTopKeyword,
	} = useTopKeyword()
	const { data: snsList, refetch: refetchSNSList, isLoading: isLoadingSNSList } = useSNSList()

	const headerRef = useRef<HTMLElement>(null)
	const hgroupRef = useRef<HTMLElement>()
	const setHeaderRef = useSetRecoilState(headerRefAtom)
	const [headerStates, setHeaderStates] = useHeaderStates()
	const handleScroll = () => {
		if (headerRef.current && hgroupRef.current) {
			if (window.scrollY > (hgroupRef.current.offsetHeight ?? 0)) {
				headerRef.current.classList.add('set-sticky')
			} else {
				headerRef.current.classList.remove('set-sticky')
			}
		}
	}
	const handleLayerClose = () => {
		setHeaderStates((prev) => ({
			...prev,
			megaMenuOpen: false,
			searchOpen: false,
		}))
	}
	const filteredHeaderState = useCallback(() => {
		const isOpenedLayer = Object.keys(headerState).find(
			(key) => headerState[key as keyof HeaderStates],
		)
		if (isOpenedLayer) {
			setRefreshValue(false)
		} else {
			setRefreshValue(true)
		}
	}, [headerState])
	useEffect(() => {
		setHeaderRef(headerRef)
	}, [])
	useEffect(() => {
		filteredHeaderState()
		return () => {
			setRefreshValue(true)
		}
	}, [filteredHeaderState])
	useEffect(() => {
		handleScroll()
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [deviceMedia])
	useEffect(() => {
		refetchMenuTop()
		refetchMenuPc()
		refetchTopKeyword()
		refetchSNSList()
	}, [pathname])

	useEffect(() => {
		document.body.classList.toggle('bgBlack', pathname.includes('video'))
	}, [pathname])

	useEffect(() => {
		if (!isLoadingMemuTop && !isLoadingMemuPc && !isLoadingTopKeyword) {
			setIsLoadPage(true)
		}
	}, [isLoadingMemuTop, isLoadingMemuPc, isLoadingTopKeyword])
	return (
		<>
			<HeaderStateManager />
			{(headerState.megaMenuOpen || headerState.searchOpen) && (
				<HeaderDimmed
					onClick={() => {
						handleLayerClose()
					}}
				/>
			)}
			{isService ? (
				<ServiceIntroHeader />
			) : (
				<HeaderBox ref={headerRef} component="header">
					<HeaderGroup ref={hgroupRef}>
						<UIContainer>
							{menuTop && Array.isArray(menuPc) && (
								<NavBox
									menuTop={menuTop}
									menuPc={menuPc}
									snsList={snsList ?? []}
									isApp={isApp}
									auth={auth}
								/>
							)}
							{topKeyword && (topKeyword?.length ?? 0) > 0 && (
								<IssueBox topKeyword={topKeyword} />
							)}
						</UIContainer>
					</HeaderGroup>
					<BreakingNews />
					<Setting />
				</HeaderBox>
			)}
		</>
	)
}
export default Header

'use client'

import { IsAppState } from '@store/isApp'
import NativeEvent from './NativeEvent'
import TokenEvent from './TokenEvent'
import ArticleEvent from './ArticleEvent'
import AnalysisEvent from './AnalysisEvent'
import dynamic from 'next/dynamic'
import useIsMounted from '@hooks/useIsMounted'

interface NavigationEventProps extends IsAppState {
	userToken: string
	cookieHeader: string
}
const NavigationEvent = ({
	isApp = false,
	appInfo,
	userToken,
	cookieHeader,
}: NavigationEventProps) => {
	const isMounted = useIsMounted()
	const LazyEvent = dynamic(() => import('./LazyEvent'), {
		loading: () => null,
	})

	return (
		<>
			<TokenEvent cookieHeader={cookieHeader} userToken={userToken} />
			<NativeEvent appInfo={appInfo} isApp={isApp} />
			<ArticleEvent />
			<AnalysisEvent />
			{isMounted && <LazyEvent />}
		</>
	)
}

export default NavigationEvent

import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

type ShortsMutedState = {
	muted: boolean
}

export const shortsMutedState = atom<ShortsMutedState>({
	key: 'shortsMutedState',
	default: {
		muted: true,
	},
})

export const useShortsMuted = () => {
	const [state, setState] = useRecoilState(shortsMutedState)

	const setShortsMuted = useCallback(
		(value: boolean) => {
			setState({ muted: value })
		},
		[setState],
	)

	return { shortsMuted: state.muted, setShortsMuted }
}

'use client'

import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useIsAppControl } from '@store/isApp'
import { sendGAEvent } from '@lib/ga4'
import usePreviousState from '@hooks/usePreviousState'
import { usePathname } from 'next/navigation'

const AnalysisEvent = () => {
	const { appInfo } = useIsAppControl()
	const pathname = usePathname()
	const prevPathname = usePreviousState(pathname)
	const prevAppInfo = usePreviousState(appInfo)

	const sendGA4DeviceType = () => {
		if (appInfo?.DType === 'A') {
			sendGAEvent('device_pv', { type: 'AOS' })
			return
		}
		if (appInfo?.DType === 'I') {
			sendGAEvent('device_pv', { type: 'IOS' })
			return
		}
		if (appInfo?.DType === 'W') {
			sendGAEvent('device_pv', { type: isMobile ? 'MOBILE_WEB' : 'PC_WEB' })
		}
	}

	const initialGA4 = (id: string) => {
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.async = true
		script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
		script.onload = () => {
			function gtag() {
				// eslint-disable-next-line prefer-rest-params
				window.dataLayer.push(arguments)
			}
			window.dataLayer = window.dataLayer || []
			window.gtag = gtag
			window.gtag('js', new Date())
			window.gtag('config', id)

			sendGA4DeviceType()
		}
		document.head.appendChild(script)
	}

	const handleLocationAnalysis = () => {
		const { newsAnalysis, document } = window
		const head = document.querySelector('head')
		if (head) {
			const title = head.querySelector('head > title')?.innerHTML ?? ''
			const metaTitle = head.querySelector('meta[name="title"]') as HTMLMetaElement
			if (metaTitle) {
				metaTitle.content = title.replace(' | JTBC뉴스', '')
			} else {
				const metaElement = document.createElement(`meta`)
				metaElement.name = 'title'
				metaElement.content = title
				head.appendChild(metaElement)
			}
		}
		if (newsAnalysis) {
			const { href } = window.location
			if (newsAnalysis.current_path !== href) {
				newsAnalysis.ref = newsAnalysis.current_path
				newsAnalysis.current_path = href
					?.split('&')
					?.filter((str: string) => !str.includes('code='))
					?.join('&')
			}
			newsAnalysis?.init('viewpage', '', '')
		}
	}

	useEffect(() => {
		if (prevPathname !== pathname) {
			sendGA4DeviceType()
		}
	}, [pathname])

	useEffect(() => {
		handleLocationAnalysis()
	}, [pathname])

	useEffect(() => {
		if (!prevAppInfo && appInfo) {
			const GA_ID =
				appInfo.DType === 'I' || appInfo.DType === 'A'
					? process.env.NEXT_PUBLIC_GA_TRACKING_MO_ID
					: process.env.NEXT_PUBLIC_GA_TRACKING_PC_ID
			initialGA4(GA_ID)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appInfo])

	return null
}
export default AnalysisEvent

'use client'

import { useAodControl } from '@store/aod'
import useIsMounted from '@hooks/useIsMounted'
import AodBarRoot, { AodReq } from './web/AodBarRoot'
import AppAodBarRoot from './app/AppAodBarRoot'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { IsAppState, appInterface } from '@store/isApp'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

interface AodBarProps {
	isApp: boolean
}
const AodBar = ({ isApp }: AodBarProps) => {
	const nativeEvent = useRecoilValue(appInterface)
	const isMounted = useIsMounted()
	const { aod, setAod } = useAodControl()
	const [appStateLocal] = useLocalStorage<string>('app_state')
	const appState = appStateLocal ? (JSON.parse(appStateLocal) as IsAppState) : undefined
	const onClose = () => {
		setAod()
	}
	useEffect(() => {
		if (isMounted && !aod.programIdx) {
			/**
			 * @description
			 * aos back key 페이지 이동 시, cleanup function issue
			 */
			nativeEvent({ key: 'exitAod', value: null })
		}
	}, [aod, isMounted, nativeEvent])
	return (
		isMounted &&
		aod.programIdx &&
		(isApp || appState?.isApp ? (
			<AppAodBarRoot data={aod as AodReq} onClose={onClose} />
		) : (
			<AodBarRoot data={aod as AodReq} onClose={onClose} />
		))
	)
}

export default AodBar

'use client'

import { useEffect } from 'react'
import { useCommentTotalCount } from '@store/comments/commentTotalCount'
import type { PropsWithChildren } from 'react'

interface GetCommentTotalCountProps {
	contentId: string
	count: number
}

interface NewsInterfaceProviderProps extends PropsWithChildren {}

const NewsInterfaceProvider = ({ children }: NewsInterfaceProviderProps) => {
	const { setCommentList } = useCommentTotalCount()

	/** [댓글] 댓글 개수 가져오는 interface */
	const getCommentTotalCount = ((event: CustomEvent<GetCommentTotalCountProps>) => {
		if (event.detail.contentId && event.detail.count > 0) {
			setCommentList({
				id: event.detail.contentId,
				count: event.detail.count,
			})
		}
	}) as EventListener

	useEffect(() => {
		window.addEventListener('getCommentTotalCount', getCommentTotalCount)
		return () => {
			window.removeEventListener('getCommentTotalCount', getCommentTotalCount)
		}
	}, [])

	return <>{children}</>
}
export default NewsInterfaceProvider

'use client'

import { Box, styled } from '@mui/joy'
import { usePathname } from 'next/navigation'
import { useLayoutEffect, useState } from 'react'
import HomeMoAd from './HomeMoAd'
import HomePcAd from './HomePcAd'
import ArticleMoAd from './ArticleMoAd'
import UIText from '@components/ui/UIText'
import pxToRem from '@utils/pxToRem'
import { theme } from '@ui/style'
import Icon16CloseTrue16 from 'src/assets/icons/Icon16CloseTrue16'
import { useMediaQuery } from '@hooks/useMediaQuery'

interface GNBBannerProps {
	isApp: boolean
}
const GNBBanner = ({ isApp }: GNBBannerProps) => {
	const pathname = usePathname()
	const [adType, setAdType] = useState<string>('')
	const [isDisplay, setIsDisplay] = useState(true)
	const isMediumDevice = useMediaQuery(`only screen and (min-width: ${theme.breakpoint.md}px)`)

	const closeBanner = () => {
		setIsDisplay(false)
	}

	useLayoutEffect(() => {
		if (pathname === '/') {
			setAdType('home')
		} else if (pathname.includes('/article')) {
			setAdType('article')
		} else {
			setAdType('')
		}
		setIsDisplay(true)
		return () => {
			document.querySelector('#article-gnb-mo')?.remove()
		}
	}, [pathname])

	const CloseButton = styled('button')(
		({ width = 16, height = 16 }: { width?: number; height?: number }) => ({
			width: pxToRem(width),
			height: pxToRem(height),
			background: 'transparent',
			position: 'absolute',
			top: '50%',
			right: pxToRem(14),
			marginTop: pxToRem(-8),
			[theme.breakpoints.up('md')]: {
				padding: pxToRem(2),
				marginTop: pxToRem(-10),
				right: 'calc((100% - (768px - (24px * 2))) / 2)',
			},
			[theme.breakpoints.up('lg')]: {
				padding: pxToRem(2),
				marginTop: pxToRem(-10),
				right: 'calc((100% - (1024px - (48px * 2))) / 2)',
			},
			[theme.breakpoints.up('xl')]: {
				right: 'calc((100% - (1280px - (32px * 2))) / 2)',
			},
		}),
	)

	if (!isApp) {
		if (adType === 'home') {
			const iconSize = isMediumDevice ? 20 : 16

			return (
				isDisplay && (
					<Box
						overflow="hidden"
						bgcolor="#f8f8f8"
						position="relative"
						className="print-hide"
					>
						<HomePcAd />
						<HomeMoAd />
						<CloseButton
							width={iconSize}
							height={iconSize}
							onClick={() => closeBanner()}
						>
							<UIText readonly>닫기</UIText>
							<Icon16CloseTrue16 width={iconSize} height={iconSize} />
						</CloseButton>
					</Box>
				)
			)
		}
		if (adType === 'article') {
			return (
				isDisplay && (
					<Box position="relative" bgcolor="#f8f8f8" className="print-hide">
						<ArticleMoAd />
					</Box>
				)
			)
		}
	}
	return null
}
export default GNBBanner

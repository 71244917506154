import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

type LiveMutedState = {
	muted: boolean
}

export const liveMutedState = atom<LiveMutedState>({
	key: 'liveMutedState',
	default: {
		muted: true,
	},
})

export const useLiveMuted = () => {
	const [state, setState] = useRecoilState(liveMutedState)

	const setLiveMuted = useCallback(
		(value: boolean) => {
			setState({ muted: value })
		},
		[setState],
	)

	return { liveMuted: state.muted, setLiveMuted }
}

import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

type EnterAppState = {
	status: string
}

export const enterAppStatus = atom<EnterAppState>({
	key: '#enterAppStatus',
	default: {
		status: '',
	},
})

export const useEnterAppStatus = () => {
	const [state, setState] = useRecoilState(enterAppStatus)

	const setEnterAppStatus = useCallback(
		(value: string) => {
			if (value) {
				setState({ status: value })
			}
		},
		[setState],
	)

	return { enterAppStatus: state.status, setEnterAppStatus }
}

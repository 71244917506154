import { atom, useRecoilState } from 'recoil'
import { useCallback } from 'react'

export interface CommentStateProps {
	id: string
	count: number
}

interface CommentListState {
	list: CommentStateProps[]
}

export const getCommentTotalCountList = atom<CommentListState>({
	key: '#getCommentTotalCountList',
	default: {
		list: [],
	},
})

export const useCommentTotalCount = () => {
	const [state, setState] = useRecoilState(getCommentTotalCountList)

	const setCommentList = useCallback(
		({ id, count }: CommentStateProps) => {
			setState((prev) => {
				if (prev.list.find((l) => l.id === id)) {
					return {
						list: prev.list.map((l) => (l.id === id ? { id, count } : l)),
					}
				}

				return {
					list: [...prev.list, { id, count }],
				}
			})
		},
		[setState],
	)

	return {
		commentList: state.list,
		setCommentList,
	}
}

interface SetAdsProps {
	id: string
	inventory: string
}
const setAds = ({ id, inventory }: SetAdsProps) => {
	// eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
	const _IMP_INVENTORY = inventory
	const scriptId = `${id}_script`
	if (!document.getElementById(scriptId)) {
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.src = '//cast-imp.jtbc.co.kr/persona.js'
		script.id = scriptId
		script.async = true
		script.setAttribute('data-id', id)
		script.setAttribute('name', _IMP_INVENTORY)
		const obj = document.getElementById(`${id}_AD`)
		obj?.appendChild(script)
	}
}

export default setAds

'use client'

import AppSplash, { AppSplashProps } from '@components/ads/AppSplash'
import useIsMounted from '@hooks/useIsMounted'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { useFCMToken } from '@lib/firebase'
import { useDeviceTokenMutation } from '@services/api/push/DeviceToken/mutation'
import { useEnterAppStatus } from '@store/enterApp'
import { IsAppState, appInterface, useIsAppControl } from '@store/isApp'
import { useSetIsMobile } from '@store/isMobile'
import { useTTSControl } from '@store/tts'
import { useRouter } from 'next/navigation'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useNotification } from '@lib/notification'
import useSetRefreshValue from '@hooks/Interface/useSetRefreshValue'

interface NativeEventProps extends IsAppState {}

interface SendSplashEndProps {
	isEnd: boolean
}
interface SendAppGetHistoryProps {
	pushToken: string
	isPushNoti: boolean
}
interface SendDeviceIsMobileProps {
	isMobile: boolean
}

interface SendTTSCompleteProps {
	sendTTSComplete: string
}

interface SendEnterBackgroundProps {
	sendEnterBackground: string
}

interface SendEnterForegroundProps {
	sendEnterForeground: string
}

const splashAds = {
	// STG 코드
	// I: 'https://cast-imp.jtbc.co.kr/bid/_SR72QdmQo6enfDQbdOl1A/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IiIsImRldmljZSI6eyJvcyI6IklPUyIsIm1vZGVsIjoiaXBob25lIiwiaCI6MCwiZGV2aWNldHlwZSI6MSwiaWZhIjoiIiwidyI6MCwibG10IjoxLCJpcCI6IjEyNy4wLjAuMSJ9LCJpbXAiOlt7Im5hdGl2ZSI6eyJyZXF1ZXN0IjoiIiwiZXh0Ijp7InNsb3RzIjoxfSwidmVyIjoiMS4wIn19XSwiYXBwIjp7ImNvbnRlbnQiOnsiZXh0Ijp7fSwiY2F0IjpbIjAxIl0sImxpdmVzdHJlYW0iOjB9LCJuYW1lIjoiSlRCQ05FV1MifSwidXNlciI6eyJnZW5kZXIiOiJPIiwiZXh0Ijp7ImFnZSI6OTl9LCJ5b2IiOjAsImN1c3RvbWRhdGEiOiIifX0.zQQnECECvi4NVCdGqEykAnRwHOHpkHV3_2hwO8uo7bI',
	// A: 'https://cast-imp.jtbc.co.kr/bid/dRDoR2rkQDyQNBuA2mXstw/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOnsibmFtZSI6IkpUQkNORVdTIiwiY29udGVudCI6eyJleHQiOnt9LCJjYXQiOlsiMDEiXSwibGl2ZXN0cmVhbSI6MH19LCJpZCI6IiIsImRldmljZSI6eyJvcyI6IkFORFJPSUQiLCJkZXZpY2V0eXBlIjoxLCJoIjowLCJtb2RlbCI6IkFuZHJvaWQiLCJpZmEiOiIiLCJsbXQiOjEsInciOjAsImlwIjoiMTI3LjAuMC4xIn0sImltcCI6W3sibmF0aXZlIjp7ImV4dCI6eyJzbG90cyI6MX0sInZlciI6IjEuMCIsInJlcXVlc3QiOiIifX1dLCJ1c2VyIjp7InlvYiI6MCwiZXh0Ijp7ImFnZSI6OTl9LCJnZW50ZXIiOiJPIiwiY3VzdG9tZGF0YSI6IiJ9fQ.L9P85pgv9UHBaC1UzO-vtB8NgXnq_GkXT0WK7VMQzIw',
	// 운영 코드
	I: 'https://cast-imp.jtbc.co.kr/bid/5DJBBq2VTG2L8qTXC3HBIg/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IiIsImRldmljZSI6eyJvcyI6IklPUyIsIm1vZGVsIjoiaXBob25lIiwiaCI6MCwiZGV2aWNldHlwZSI6MSwiaWZhIjoiIiwidyI6MCwibG10IjoxLCJpcCI6IjEyNy4wLjAuMSJ9LCJpbXAiOlt7Im5hdGl2ZSI6eyJyZXF1ZXN0IjoiIiwiZXh0Ijp7InNsb3RzIjoxfSwidmVyIjoiMS4wIn19XSwiYXBwIjp7ImNvbnRlbnQiOnsiZXh0Ijp7fSwiY2F0IjpbIjAxIl0sImxpdmVzdHJlYW0iOjB9LCJuYW1lIjoiSlRCQ05FV1MifSwidXNlciI6eyJnZW5kZXIiOiJPIiwiZXh0Ijp7ImFnZSI6OTl9LCJ5b2IiOjAsImN1c3RvbWRhdGEiOiIifX0.3XisZS2gCJxKsh89cmcLwWa599DMH2UsbvUOuG2NQog',
	A: 'https://cast-imp.jtbc.co.kr/bid/RMNZVNYtTRyWHQHVGEh3HQ/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOnsibmFtZSI6IkpUQkNORVdTIiwiY29udGVudCI6eyJleHQiOnt9LCJjYXQiOlsiMDEiXSwibGl2ZXN0cmVhbSI6MH19LCJpZCI6IiIsImRldmljZSI6eyJvcyI6IkFORFJPSUQiLCJkZXZpY2V0eXBlIjoxLCJoIjowLCJtb2RlbCI6IkFuZHJvaWQiLCJpZmEiOiIiLCJsbXQiOjEsInciOjAsImlwIjoiMTI3LjAuMC4xIn0sImltcCI6W3sibmF0aXZlIjp7ImV4dCI6eyJzbG90cyI6MX0sInZlciI6IjEuMCIsInJlcXVlc3QiOiIifX1dLCJ1c2VyIjp7InlvYiI6MCwiZXh0Ijp7ImFnZSI6OTl9LCJnZW50ZXIiOiJPIiwiY3VzdG9tZGF0YSI6Ijc3MUY0NDFCMkE3OUE2QjE2MDAyMkE2NzFDNDdCNTkyIn19.zRVC2xwZwLssAR3xbhulpgl3_ixCrcthoaB3N4EgbXs',
}
const NativeEvent = ({ isApp = false, appInfo }: NativeEventProps) => {
	const nativeEvent = useRecoilValue(appInterface)
	const setIsMobile = useSetIsMobile()
	const isMounted = useIsMounted()
	const [isAppSplashEnd, setIsAppSplashEnd] = useState(false)
	const [sendNativeEvent, setSendNativeEvent] = useState<boolean>(false)
	const [alarmUseYn] = useLocalStorage<boolean>('alarm_use_yn')
	const [appStateLocal, setAppState] = useLocalStorage<string | undefined>('app_state')
	const [newsAlarmYn, setNewsAlarmYn] = useLocalStorage<boolean>('news_alarm_yn')
	const [subNewsAlarmYn, setSubNewsAlarmYn] = useLocalStorage<boolean>('sub_news_alarm_yn')
	const [replyAlarmYn, setReplyAlarmYn] = useLocalStorage<boolean>('reply_alarm_yn')
	const [streamingAlarmYn, setStreamingAlarmYn] = useLocalStorage<boolean>('streaming_alarm_yn')
	const [etiquetteYn, setEtiquetteYn] = useLocalStorage<boolean>('etiquette_yn')
	const [networkYn, setNetworkYn] = useLocalStorage<boolean>('network_yn')
	const [splashAd, setSplashAd] = useState<Array<AppSplashProps> | null>(null)
	const { setIsApp, setAppInfo } = useIsAppControl()
	const { setEnterAppStatus } = useEnterAppStatus()
	const fcmToken: string | null = useFCMToken()
	const { mutateAsync: updateDeviceToken } = useDeviceTokenMutation()
	const { setIsTTSPlaying } = useTTSControl()
	const { notify } = useNotification()
	const { setRefreshValue } = useSetRefreshValue()

	const isYN = (value: boolean) => (value ? 'Y' : 'N')

	const updateAppDeviceToken = useCallback(
		({ pushToken, isPushNoti }: SendAppGetHistoryProps) => {
			const appState = appStateLocal ? JSON.parse(appStateLocal) : undefined

			if (appState?.appInfo?.DType) {
				const useYN = isYN(isPushNoti)
				const isChangedAlarm = alarmUseYn !== isPushNoti
				updateDeviceToken({
					deviceToken: pushToken,
					dtype: appState?.appInfo?.DType === 'I' ? 'IOS' : 'AOS',
					alarmUseYn: useYN,
					newsAlarmYn:
						newsAlarmYn === undefined || isChangedAlarm ? useYN : isYN(newsAlarmYn),
					subNewsAlarmYn:
						subNewsAlarmYn === undefined || isChangedAlarm
							? useYN
							: isYN(subNewsAlarmYn),
					replyAlarmYn:
						replyAlarmYn === undefined || isChangedAlarm ? useYN : isYN(replyAlarmYn),
					streamingAlarmYn:
						streamingAlarmYn === undefined || isChangedAlarm
							? useYN
							: isYN(streamingAlarmYn),
				})
				if (newsAlarmYn === undefined) {
					setNewsAlarmYn(isPushNoti)
				}
				if (subNewsAlarmYn === undefined) {
					setSubNewsAlarmYn(isPushNoti)
				}
				if (replyAlarmYn === undefined) {
					setReplyAlarmYn(isPushNoti)
				}
				if (streamingAlarmYn === undefined) {
					setStreamingAlarmYn(isPushNoti)
				}
			}
		},
		[appStateLocal, alarmUseYn, newsAlarmYn, subNewsAlarmYn, replyAlarmYn, streamingAlarmYn],
	)
	/** APP 스플래쉬 종료 시점 */
	const sendSplashEnd = ((event: CustomEvent<SendSplashEndProps>) => {
		if (event.detail.isEnd) {
			setIsAppSplashEnd(true)
		}
	}) as EventListener
	/** APP Push Token, 알림 권한 정보 수신 */
	const sendAppPushInfo = ((event: CustomEvent<SendAppGetHistoryProps>) => {
		setAppInfo(event.detail)
		updateAppDeviceToken(event.detail)
	}) as EventListener
	/** APP TTS 완독시 수신 */
	const sendTTSComplete = ((event: CustomEvent<SendTTSCompleteProps>) => {
		setIsTTSPlaying(false)
		notify('본문 듣기를 종료 하였습니다.')
		console.log('event TTS', event)
	}) as EventListener
	/** APP 뒤로가기 수신 */
	const router = useRouter()
	const sendHistoryBack = ((event: CustomEvent<SendTTSCompleteProps>) => {
		console.log('sendHistoryBack event::', event)
		if (appInfo?.DType === 'A' && !window.history.length) {
			router.push('/') // 히스토리 없을 시 홈으로
		}
	}) as EventListener
	/** APP 모바일 데이터 사용 여부 수신 */
	const sendDeviceIsMobile = ((event: CustomEvent<SendDeviceIsMobileProps>) => {
		const { isMobile } = event.detail
		setIsMobile(isMobile)
		// console.log('sendDeviceIsMobile', event)
	}) as EventListener
	/** APP 포그라운드 이벤트 수신 */
	const sendEnterForeground = ((event: CustomEvent<SendEnterForegroundProps>) => {
		console.log('Foreground event', event)
		setEnterAppStatus('foreground')
	}) as EventListener
	/** APP 백그라운드 이벤트 수신 */
	const sendEnterBackground = ((event: CustomEvent<SendEnterBackgroundProps>) => {
		console.log('Background event', event)
		setEnterAppStatus('background')
	}) as EventListener

	const getSplashAd = async (DType: 'I' | 'A') => {
		const response = await fetch(splashAds[DType], {})
		const data = await response.json()
		if (!data.error) {
			setSplashAd(Array.isArray(data) ? data : [data])
		} else {
			nativeEvent({
				key: 'requestDeviceOrientation',
				value: {
					isPrevent: false,
				},
			})
			setRefreshValue(true)
		}
	}

	useEffect(() => {
		if (appInfo && appInfo.DType !== 'W' && isAppSplashEnd && !splashAd) {
			getSplashAd(appInfo.DType)
		}
	}, [appInfo, splashAd, isAppSplashEnd])

	useEffect(() => {
		let state: IsAppState | undefined
		if (isApp) {
			state = { isApp, appInfo }
			setIsApp(state)
			setAppState(JSON.stringify(state))
		} else if (appStateLocal) {
			state = JSON.parse(appStateLocal)
			setIsApp(state)
			setSendNativeEvent(false)
		}
		if (!state) {
			if (fcmToken) {
				const useYN = Notification.permission === 'granted'
				setIsApp({
					isApp: false,
					appInfo: {
						DType: 'W',
						pushToken: fcmToken,
						isPushNoti: useYN,
					},
				})
				updateDeviceToken({
					deviceToken: fcmToken,
					dtype: 'WEB',
					alarmUseYn: isYN(useYN),
					newsAlarmYn: isYN(useYN),
					subNewsAlarmYn: isYN(useYN),
					replyAlarmYn: isYN(useYN),
					streamingAlarmYn: isYN(useYN),
				})
			} else {
				setIsApp({
					isApp: false,
					appInfo: {
						DType: 'W',
					},
				})
			}
		}
		window.addEventListener('sendTTSComplete', sendTTSComplete)
		window.addEventListener('sendSplashEnd', sendSplashEnd)
		window.addEventListener('sendAppPushInfo', sendAppPushInfo)
		window.addEventListener('sendDeviceIsMobile', sendDeviceIsMobile)
		window.addEventListener('sendEnterForeground', sendEnterForeground)
		window.addEventListener('sendEnterBackground', sendEnterBackground)
		window.addEventListener('sendHistoryBack', sendHistoryBack)
		return () => {
			window.removeEventListener('sendTTSComplete', sendTTSComplete)
			window.removeEventListener('sendSplashEnd', sendSplashEnd)
			window.removeEventListener('sendAppPushInfo', sendAppPushInfo)
			window.removeEventListener('sendDeviceIsMobile', sendDeviceIsMobile)
			window.removeEventListener('sendEnterForeground', sendEnterForeground)
			window.removeEventListener('sendEnterBackground', sendEnterBackground)
			window.removeEventListener('sendHistoryBack', sendHistoryBack)
		}
	}, [appStateLocal, fcmToken])

	useEffect(() => {
		if (isMounted) {
			if (etiquetteYn === undefined) {
				setEtiquetteYn(false)
			}
			if (networkYn === undefined) {
				setNetworkYn(false)
			}
		}
	}, [isMounted, etiquetteYn, networkYn])

	useEffect(() => {
		if (!sendNativeEvent && isMounted) {
			setTimeout(() => {
				/** APP Push Token, 알림 권한 정보 호출 */
				nativeEvent({
					key: 'requestAppPushInfo',
					value: null,
				})
				/** APP 모바일 데이터 사용 여부 호출 */
				nativeEvent({
					key: 'requestDeviceIsMobile',
					value: null,
				})
				setSendNativeEvent(true)
			}, 100)
		}
	}, [nativeEvent, sendNativeEvent, isMounted])

	return (
		splashAd &&
		splashAd.map((adData) => <AppSplash key={`ad-${adData.image_file}`} {...adData} />)
	)
}
export default NativeEvent

'use client'

import UIFlex from '@components/ui/UIFlex'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import color from '@ui/style/color.theme'
import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'
import { IsAppState } from '@store/isApp'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { useVideoResolution } from '@store/videoResolution'
import { useShortsMuted } from '@store/shortsMuted'
import { useLiveMuted } from '@store/liveMuted'
import { useProgramMuted } from '@store/programMuted'

import {
	scrollToTop,
	scrollToTopAndEnableAutoScrollRestoration,
	enableAutoScrollRestoration,
} from '@utils/scroll'

interface RootLayoutWrapperProps {
	children: React.ReactNode
}
interface PathCheck {
	isMain: boolean
	isDetail: boolean
}

const RootLayoutWrapper = ({ children }: RootLayoutWrapperProps) => {
	const pathname = usePathname()
	const prevPathRef = useRef<string>(pathname)
	const [appStateLocal] = useLocalStorage<string>('app_state')
	const appState = appStateLocal ? (JSON.parse(appStateLocal) as IsAppState) : undefined
	const checkPath = (currentPath: string, comparePath: string): PathCheck => {
		return {
			isMain: currentPath === `/${comparePath}`,
			isDetail: currentPath.startsWith(`/${comparePath}/`),
		}
	}

	const { setShortsMuted } = useShortsMuted()
	const { setVideoResolution } = useVideoResolution()
	const { setLiveMuted } = useLiveMuted()
	const { setProgramMuted } = useProgramMuted()

	useEffect(() => {
		scrollToTop()

		const { isMain: isService } = checkPath(pathname, 'service')
		const { isDetail: isIssuesDetail } = checkPath(pathname, 'issues')
		const { isDetail: isTopicDetail } = checkPath(pathname, 'topic')
		const { isDetail: isArticleDetail } = checkPath(pathname, 'article')
		const { isMain: isLive, isDetail: isLiveDetail } = checkPath(pathname, 'live')
		const { isDetail: isVideoDetail } = checkPath(pathname, 'video')

		if (
			isService ||
			isArticleDetail ||
			isLive ||
			isLiveDetail ||
			isVideoDetail ||
			(isIssuesDetail && prevPathRef.current === '/issues') ||
			(isTopicDetail && prevPathRef.current === '/issues') ||
			(isVideoDetail && prevPathRef.current.startsWith('/article'))
		) {
			scrollToTopAndEnableAutoScrollRestoration()
		} else {
			enableAutoScrollRestoration()
		}

		if (!pathname.includes('/shorts')) {
			setShortsMuted(true)
		}

		if (!pathname.includes('/live')) {
			setLiveMuted(true)
		}

		if (!pathname.includes('/live')) {
			setVideoResolution('720')
		}

		if (!pathname.includes('/video')) {
			setProgramMuted(true)
		}

		prevPathRef.current = pathname
	}, [pathname, setVideoResolution, setShortsMuted, setLiveMuted, setProgramMuted])

	const { darkMode } = useDarkModeControl()
	return (
		<UIFlex
			id="wrapper"
			sx={{ minHeight: '100%' }}
			bgcolor={darkMode ? color.colBlack : 'transparent'}
		>
			{children}
		</UIFlex>
	)
}

export default RootLayoutWrapper

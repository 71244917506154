'use client'

import { useHandleQueryError } from '@hooks/useHandleQueryError'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental'
import { useState } from 'react'

export function ReactQueryProvider({ children }: React.PropsWithChildren) {
	const { handleQueryError } = useHandleQueryError()
	const [client] = useState(
		new QueryClient({
			defaultOptions: {
				queries: {
					staleTime: 1000 * 60,
					throwOnError: true,
					retry: 1,
					refetchOnMount: 'always', // refetch force
				},
			},
			queryCache: new QueryCache({
				onError: (error, query) => {
					if (query.options.meta?.skipGlobalErrorHandler) {
						return
					}
					handleQueryError({ error })
				},
			}),
			mutationCache: new MutationCache({
				onError: (error, variables, context, mutation) => {
					if (mutation.options.onError) {
						return
					}
					handleQueryError({ error })
				},
			}),
		}),
	)
	/**
	 * @description
	 * unmount - API 호출 이슈
	 * 쿼리 캐시 무효화(invalidateQueries) ==> 전역 옵션 추가
	 * refetchOnMount: 'always'
	 * @TODO
	 * 문제 없을 시 주석 삭제 예정
		const handleInvalidate = useCallback(() => {
			const hydrate = dehydrate(client)
			client.invalidateQueries(hydrate.queries as InvalidateQueryFilters)
		}, [client])
		useLayoutEffect(() => {
			handleInvalidate()
		}, [pathname, handleInvalidate])
	*/
	return (
		<QueryClientProvider client={client}>
			<ReactQueryStreamedHydration>{children}</ReactQueryStreamedHydration>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	)
}
